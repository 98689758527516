export const Localization = {
  currencySymbol: '৳',
  currencyAbbrev: 'BDT',
  branch: 'Branch',
  branches: 'Branches',
  collectionSpecialist: 'Collection Specialist',
  csLeaderBoard: 'CS Leader Board',
  branchLeaderBoard: 'Branch Leader Board',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Collection Specialist Performance',
  branchListingHeader: 'Branch Listing',
  branchListingBreadcrumb: 'Branch Listing',
  csListingHeader: 'Collection Specialist Listing',
  csListingBreadcrumb: 'Collection Specialist Listing',
  csDetailsBreadcrumb: 'Specialist',
  logoFilename: 'tvsabl.png',
  clientName: 'TVSABL',
  navbarBackgroundColor: '#2A2A3E',
  showManuals: false,
  showAIManuals: false,
  showAPK: false,
  csManualLink: '',
  cashierManualLink: '',
  managerManualLink: '',
  aiManualLink: '',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_CDE_webapp.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_JMPL_webapp.json',
  zipcodeLength: 4,
  zipcodeValidationMsg: 'Bangladesh zipcode should have 4 characters. Ex: 7870, 1230',
  country: 'Bangladesh',
  countryAbbrev: 'BD',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: false,
  currencyLocale: 'en-IN',
  hiddenPenaltyField: false,
};
