import React from 'react';
import { Container } from 'reactstrap';

const Footer = () => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      height: '30px',
      background: '#fff',
      borderTop: '1px solid #DAE0F7',
      width: '100%',
      padding: '20px 0',
      zIndex: 10,
    }}
  >
    <Container className="text-center d-flex justify-content-center align-items-center h-100">
      <img src={`${process.env.PUBLIC_URL}/images/powered_by.png`} alt="powered by tvs" />
    </Container>
  </div>
);

export default Footer;
