/* eslint-disable @typescript-eslint/naming-convention */
import { BORROWER_ROLE } from 'constant';
import httpClient from './request';

const apiUrl = process.env.REACT_APP_API_URL! + process.env.REACT_APP_API_PREFIX;
const tokenKey = `${process.env.REACT_APP_BASENAME}.token`;
const tokenDataKey = `${process.env.REACT_APP_BASENAME}.token_data`;
const settingsKey = `${process.env.REACT_APP_BASENAME}.settings`;
interface LoginData {
  token: string;
  expire: string;
  refresh_token: string;
  s;
}

type CustomerLoginReq = {
  phone_number: string;
  country_code: string;
  borrower_otp: number;
};

const customerLogin = (payload: CustomerLoginReq): Promise<any> => {
  const reqOpts = {
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return httpClient(`${apiUrl}/borrower/login`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        const data = {
          user: {
            ...resp?.borrower,
            role: 'B',
            usertc: resp?.borrowertc,
          },
          settings: resp?.settings,
        };
        setToken(data);
        return Promise.resolve(data);
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    },
  );
};

const login = (payload: any): Promise<LoginData> => {
  const reqOpts = {
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return httpClient(`${apiUrl}/cms/login`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        setToken(resp);
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    },
  );
};

const getToken = () => {
  if (localStorage.getItem(tokenKey)) {
    return JSON.parse(localStorage.getItem(tokenKey) || '{}');
  }
  return false;
};

const getTokenData = () => {
  if (localStorage.getItem(tokenDataKey)) {
    return JSON.parse(localStorage.getItem(tokenDataKey) || '{}');
  }
  return false;
};
const getSettingsData = () => {
  if (localStorage.getItem(settingsKey)) {
    return JSON.parse(localStorage.getItem(settingsKey) || '{}');
  }
  return false;
};
const setToken = (resp: any) => {
  if (resp && resp.user && resp.settings) {
    const { acc_token } = resp.user;
    localStorage.setItem(tokenDataKey, JSON.stringify(resp.user));
    localStorage.setItem(tokenKey, JSON.stringify(acc_token));
    localStorage.setItem(settingsKey, JSON.stringify(resp.settings));
    return acc_token;
  }
  return false;
};

// called when the user navigates to a new location
const checkAuth = () => {
  return getToken() ? Promise.resolve() : Promise.reject();
};

// called when the user attempts to logout
const logout = (): Promise<any> => {
  const reqOpts = {
    method: 'POST',
  };
  const data = getTokenData();

  if (!getToken()) {
    return Promise.resolve(removeToken());
  }
  const logoutEndpoint = data?.role === BORROWER_ROLE ? `${apiUrl}/borrower/logout` : `${apiUrl}/cms/logout`;

  return httpClient(logoutEndpoint, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(removeToken());
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    },
  );
};

const removeToken = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenDataKey);
};

// get the current user details
const getUserDetails = () => {
  const reqOpts = {
    method: 'GET',
  };

  return httpClient(`${apiUrl}/users/me`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  );
};

const authProvider = {
  customerLogin,
  login,
  logout,
  checkAuth,
  getToken,
  getTokenData,
  removeToken,
  getUserDetails,
  getSettingsData,
};
export default authProvider;
