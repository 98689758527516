/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import TimeIdle from 'components/elements/IdleTime';
import { Footer } from 'components/layouts';
import { IDLE_TIMEOUT, Localization } from 'constant';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { userLogout } from 'redux/auth/actions';
import styled from 'styled-components';
import './customer.scss';
import { State } from 'redux/types';

type CustomerTemplateProps = {
  children?: FC;
};

const CustomerTemplate: FC<CustomerTemplateProps> = ({ children }) => {
  const [isTimeout, setIsTimeout] = useState(false);
  const dispatch = useDispatch();
  const { auth } = useSelector((state: State) => state);

  useEffect(() => {
    const timer = new TimeIdle({
      timeout: IDLE_TIMEOUT,
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpire: () => {
        handleTimeout();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const history = useHistory();

  const handleLogout = () => {
    dispatch(userLogout());
    history.push(
      `/borrower/login?country_code=${auth.user.country_code.replace('+', '')}&phone_number=${auth.user.phone_number}`,
    );
  };

  const handleTimeout = () => {
    history.push(
      `/borrower/login?country_code=${auth.user.country_code.replace('+', '')}&phone_number=${auth.user.phone_number}`,
    );
    dispatch(userLogout());
    setIsTimeout(false);
  };

  return (
    <Main>
      <Modal isOpen={isTimeout} centered>
        <ModalBody>
          <div>Your session is expired. Please login again</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleTimeout()} color="primary border-0">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Container
        fluid
        style={{
          background: '#252F74',
          borderBottom: '4px solid red',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
            alt={Localization.clientName}
            height={60}
          />
        </Link>
        <Button onClick={handleLogout} color="link" style={{ border: 'none', color: '#fff' }}>
          Logout
        </Button>
      </Container>
      <Container className="mt-4" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: '100px' }}>
        <div className="m-lg-0 m-md-0 m-3">{children}</div>
      </Container>
      <Footer />
    </Main>
  );
};
const Main = styled.div`
  background-color: #faf8fe;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

export default CustomerTemplate;
