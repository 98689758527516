/* eslint-disable @typescript-eslint/no-redeclare */
/**
 * Authentication saga
 */

import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import authProvider from 'utils/authProvider';
import { push } from 'connected-react-router';
import { BaseAction } from 'redux/types';
import { USER_LOGIN, USER_CHECK, USER_LOAD, USER_LOGOUT, CUSTOMER_USER_LOGIN } from './types';
import { userLoad } from './actions';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* logout(action: any) {
  const { meta } = action;
  const { success, failure } = meta;
  try {
    const resp: ResponseGenerator = yield call(authProvider.logout);
    yield put({ meta, type: success, payload: resp });
  } catch (error: any) {
    yield put({ meta, type: failure });
    // not big duel
  }
}

function* customerLogin(action: BaseAction) {
  const { payload, meta } = action;
  const { success, failure, redirect } = meta;
  try {
    const resp: ResponseGenerator = yield call(authProvider.customerLogin, payload);
    yield put({ meta, type: success, payload: resp });
    yield put(push(redirect || '/'));
  } catch (error: any) {
    yield put({ meta, type: failure, error: error?.message });
  }
}

function* userLogin(action: BaseAction) {
  const { payload, meta } = action;
  const { success, failure, redirect } = meta;
  try {
    const resp: ResponseGenerator = yield call(authProvider.login, payload);
    yield put({ meta, type: success, payload: resp });
    yield put(push(redirect || '/'));
  } catch (error: any) {
    yield put({ meta, type: failure, error: error?.message });
  }
}

function* userCheck(action: BaseAction) {
  const { redirect } = action.meta;
  try {
    yield call(authProvider.checkAuth);
    const { auth } = yield select();
    if (!auth.user || auth.user.fromCache) {
      yield put(userLoad());
    }
  } catch (error: any) {
    if (window.location.pathname !== '/login') {
      yield put(push(redirect || '/login'));
    }
    yield call(authProvider.logout);
  }
}

function* loadUser(action: BaseAction) {
  // const { meta } = action;
  // const { success, failure, cancel } = meta;
  // try {
  //   const userDetails: ResponseGenerator = yield call(authProvider.getUserDetails);
  //   yield put({ meta, type: success, payload: userDetails });
  // } catch (error) {
  //   yield put({ meta, type: failure, error: error.message });
  // } finally {
  //   if (yield cancel()) {
  //     yield put({ meta, type: cancel });
  //   }
  // }
}

function* authSaga() {
  yield takeLatest(CUSTOMER_USER_LOGIN, customerLogin);
  yield takeLatest(USER_LOGIN, userLogin);
  yield takeEvery(USER_CHECK, userCheck);
  yield takeEvery(USER_LOAD, loadUser);
  yield takeEvery(USER_LOGOUT, logout);
}

export default authSaga;
