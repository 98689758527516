/* eslint-disable no-case-declarations */
import { Localization } from 'constant/config';
import { FC } from 'react';
import AuthTemplate from 'themes/AuthTemplate';
import CustomerTemplate from 'themes/CustomerTemplate';

interface IRouterPattern {
  path: string;
  noAuth?: boolean;
  pageComponent: string;
  isExact?: boolean;
  layout?: FC;
}

let transactionRoutes: IRouterPattern[] = [];
if (Localization.activateTransactionListing) {
  transactionRoutes = [
    {
      path: '/transaction/:type/:id',
      pageComponent: 'Transaction/detail',
      layout: AuthTemplate,
    },
    {
      path: '/transactions',
      pageComponent: 'Transaction/list',
      layout: AuthTemplate,
    },
  ];
}

let memoRoutes: IRouterPattern[] = [];
if (Localization.activateCreditDebitMemo) {
  memoRoutes = [
    {
      path: '/record-memo/:id',
      pageComponent: 'Memo/record-memo',
      layout: AuthTemplate,
    },
  ];
}

const loginRoute: IRouterPattern[] = [
  {
    path: '/login',
    noAuth: true,
    pageComponent: 'Login',
  },
  {
    path: '/borrower/login',
    noAuth: true,
    pageComponent: 'Login',
  },
];

const dashboardRoutes: IRouterPattern[] = [
  {
    path: '/dashboard',
    pageComponent: 'Dashboard',
    layout: AuthTemplate,
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    layout: AuthTemplate,
  },
];

const borrowerRoutes: IRouterPattern[] = [
  {
    path: '/detail/:id',
    pageComponent: 'Customer/detail',
    layout: CustomerTemplate,
  },
];

const cashierRoutes: IRouterPattern[] = [
  {
    path: '/record-payment/:id',
    pageComponent: 'Payment/record-payment',
    layout: AuthTemplate,
  },
  ...memoRoutes,
  ...transactionRoutes,
];

const adminRoutes: IRouterPattern[] = [
  {
    path: '/version',
    noAuth: true,
    pageComponent: 'Version',
  },
  {
    path: '/collection-detail/:id',
    pageComponent: 'Collection/detail',
    layout: AuthTemplate,
  },
  {
    path: '/specialist-detail/:id',
    pageComponent: 'Specialist/detail',
    layout: AuthTemplate,
  },
  {
    path: '/specialist',
    pageComponent: 'Specialist/list',
    layout: AuthTemplate,
  },
  {
    path: '/skipped-visit',
    pageComponent: 'Collection/skip-visit',
    layout: AuthTemplate,
  },
  {
    path: '/cashier-detail/:id',
    pageComponent: 'Cashier/detail',
    layout: AuthTemplate,
  },
  {
    path: '/cashiers',
    pageComponent: 'Cashier/list',
    layout: AuthTemplate,
  },
  {
    path: '/branches',
    pageComponent: 'Branch/list',
    layout: AuthTemplate,
  },
  {
    path: '/cs-itinerary',
    pageComponent: 'CsItinerary',
    layout: AuthTemplate,
  },
  {
    path: '/accounts',
    pageComponent: 'Account/list',
    layout: AuthTemplate,
  },
  {
    path: '/account/:id',
    pageComponent: 'Account/detail',
    layout: AuthTemplate,
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    isExact: true,
    layout: AuthTemplate,
  },
  ...memoRoutes,
  ...transactionRoutes,
];

const getRoutes = (role: string) => {
  switch (role) {
    case 'A':
      return [...loginRoute, ...cashierRoutes, ...dashboardRoutes];
    case 'C':
    case 'M':
      return [...loginRoute, ...adminRoutes, ...dashboardRoutes];
    case 'B':
      return [
        ...borrowerRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: CustomerTemplate,
        })),
      ];
    default:
      return [...loginRoute, ...dashboardRoutes, ...cashierRoutes];
  }
};

export default getRoutes;
