import { isString } from 'formik';
import { Localization } from './config';

// eslint-disable-line
// TODO: check if the Localization block in this file is sufficient
// ^ (or should be dynamic)
export { Localization } from './config';

export const TVS_FORMAT_DATE = 'DD/MM/yyyy';
export const BE_FORMAT_DATE = 'yyyy-MM-DD';

export const ACCURACY_MAPPING = {
  LOW: {
    color: '#CF0000',
    text: 'Low',
  },
  MED: {
    color: '#F35F04',
    text: 'Medium',
  },
  HIGH: {
    color: '#00A061',
    text: 'High',
  },
};

export const checkForCompactness = (value: string | number) => {
  const shortHandValuesDefault = ['k', 'M', 'B'];
  const shortHandValuesIndian = ['T', 'L', 'Cr'];

  if (Localization.currencyLocale === 'en-IN') {
    for (const shortHandValue of shortHandValuesIndian) {
      if (value.toString().includes(shortHandValue)) {
        return true;
      }
    }
  } else {
    for (const shortHandValue of shortHandValuesDefault) {
      if (value.toString().includes(shortHandValue)) {
        return true;
      }
    }
  }

  return false;
};

export const formatCurrency = (value: string | number, noDecima?: boolean) => {
  if (value === null || value === undefined || value === '') return '';

  if (checkForCompactness(value)) {
    return value;
  }

  const num = Number(value?.toString().replace(/,/gi, ''));

  const currencyValue = new Intl.NumberFormat(Localization.currencyLocale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(num);

  return currencyValue;
  // const splitDecima = num.toString().split('.');
  // let num2;
  // if (+splitDecima[0] >= 0) {
  //   num2 = splitDecima[0].split(/(?=(?:\d{3})+$)/).join(',');
  // } else {
  //   num2 = `- ${Math.abs(+splitDecima[0])
  //     .toString()
  //     .split(/(?=(?:\d{3})+$)/)
  //     .join(',')}`;
  // }
  // if (noDecima) {
  //   return `${num2}${splitDecima[1] ? `.${splitDecima[1]}` : ''}`;
  // }
  // return `${num2}${splitDecima[1] ? `.${splitDecima[1]}` : '.00'}`;
};

export const PAYMENT_NOTES = ['Full Payment', 'Partial Payment', 'Promise to Pay'];

export const PTP_LOCATION = ['Branch', 'Field'];

export const OWNER_OF_PHONE_NUMBER = [
  'Customer',
  'Parent / Parent-in-law',
  'Sibling',
  'Child / Grandchild',
  'Grandparent',
  'Spouse / Partner',
  'Friend',
  'Other',
];

export const CASHIER_ROLE = 'A';
export const MANAGER_ROLE = 'M';
export const COOPERATOR_ROE = 'C';
export const BORROWER_ROLE = 'B';

export const PARTIAL_PAYMENT = 'Partial Payment';
export const PROMISE_TO_PAY = 'Promise to Pay';
export const CALL_BACK_LATER = 'Call Back Later';
export const FULL_PAYMENT = 'Full Payment';
export const ALREADY_MADE_PAYMENT = 'Already Made Payment';
export const NOT_CONTACTABLE = 'Not Contactable';
export const LEFT_MESSAGE = 'Left Message';

export const isStyleObject = (obj: any) => typeof obj === 'object';
// export const TERM_VERSION = 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.json';
export const IDLE_TIMEOUT = 1800;

export const ADDRESS_TYPE = [
  {
    id: 'Home',
    value: 'Home',
    label: 'Home',
  },
  {
    id: 'Office',
    value: 'Office',
    label: 'Office',
  },
  {
    id: 'Relative',
    value: 'Relative',
    label: 'Relative',
  },
  {
    id: 'Comaker',
    value: 'Comaker',
    label: 'Comaker',
  },
  {
    id: 'Other',
    value: 'Other',
    label: 'Other',
  },
];

export const NEPAL_MONTH_LIST = [
  'Baishakh',
  'Jestha',
  'Ashadh',
  'Shrawan',
  'Bhadra',
  'Ashoj',
  'Kartik',
  'Mangsir',
  'Poush',
  'Magh',
  'Falgun',
  'Chaitra',
];

export const MONTH_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const NEPAL_TZ = 'Asia/Kathmandu';

// **** End of Localization for Nepal ****

// Credit Debit Memo Feature changes
export const MEMO_TYPE = Localization.hiddenPenaltyField
  ? ['Principal Credit', 'Principal Debit']
  : ['Principal Credit', 'Principal Debit', 'Penalty Credit', 'Penalty Debit'];

const MEMO_REASON_CODE_PPD = Localization.activatePPD ? ['Missing PPD adjustment'] : [];
const MEMO_REASON_CODE_PENALTY = Localization.hiddenPenaltyField ? [] : ['Penalty discount approved by management'];

export const MEMO_REASON_CODE = [
  'General adjustment',
  'Corrections on misposted transactions',
  ...MEMO_REASON_CODE_PPD,
  'Principal discount approved by management',
  ...MEMO_REASON_CODE_PENALTY,
  'Others',
];

const TRANSACTION_TYPE_PPD = Localization.activatePPD ? [{ id: 'D', value: 'PPD', label: 'PPD' }] : [];

const TRANSACTION_TYPE_PENALTY_FIELDS = [
  { id: 'PECM', value: 'Penalty Credit', label: 'Penalty Credit' },
  { id: 'PEDM', value: 'Penalty Debit', label: 'Penalty Debit' },
];

const TRANSACTION_TYPE_PENALTY = Localization.hiddenPenaltyField ? [] : TRANSACTION_TYPE_PENALTY_FIELDS;

export const TRANSACTION_TYPE = [
  {
    id: 'All',
    value: 'All Transactions',
    label: 'All Transactions',
  },
  {
    id: 'P',
    value: 'Payment',
    label: 'Payment',
  },
  ...TRANSACTION_TYPE_PPD,
  {
    id: 'PRCM',
    value: 'Principal Credit',
    label: 'Principal Credit',
  },
  {
    id: 'PRDM',
    value: 'Principal Debit',
    label: 'Principal Debit',
  },
  ...TRANSACTION_TYPE_PENALTY,
  {
    id: 'M',
    value: 'All credit/debit memo',
    label: 'All credit/debit memo',
  },
];

/**
 * Parent function that formats value compactly based on locale value
 * @param {*} num the number to displayed compactly
 * @param {*} locale locale
 * @returns compacted value with upto 2 decimal digits
 */
export const kFormatter = (num: number | string) => {
  if (checkForCompactness(num) || isString(num)) {
    return num;
  }

  if (Localization.currencyLocale === 'en-IN') {
    return kFormatterIndian(num); // Returns the value as per the Indian numbering system of T, L, Cr etc
  }

  return kFormatterDefault(num); // Returns the value as per the international system of k, M, B etc
};

export const kFormatterDefault = (num: number) => {
  const absValue = Math.abs(num);
  const signNum = Math.sign(num);

  if (absValue >= 1e5 && absValue < 1e6) {
    return `${numberWithCommas((signNum * (absValue / 1e3)).toFixed(1))}k`;
  }
  if (absValue >= 1e6 && absValue < 1e9) {
    return `${numberWithCommas((signNum * (absValue / 1e6)).toFixed(1))}M`;
  }
  if (absValue >= 1e9 && absValue < 1e12) {
    return `${numberWithCommas((signNum * (absValue / 1e9)).toFixed(1))}B`;
  }

  return numberWithCommas(absValue.toFixed(1));
};

/**
 * Returns the value compactly as per Indian numbering system of T, L, Cr etc
 * @param {*} num
 * @returns the compacted value with upto 2 decimal digits
 */
export const kFormatterIndian = (num: number) => {
  const absValue = Math.abs(num);
  const signNum = Math.sign(num);

  // if (n >= 1e3 && n < 1e5) {
  //   return this.numberWithCommas(sign_num * (n / 1e3).toFixed(2)) + 'T';
  // } else
  if (absValue >= 1e5 && absValue < 1e7) {
    return `${numberWithCommas((signNum * (absValue / 1e5)).toFixed(1))}L`;
  }
  if (absValue >= 1e7 && absValue < 1e11) {
    return `${numberWithCommas((signNum * (absValue / 1e7)).toFixed(1))}Cr`;
  }

  // Return the actual value without any compacting in appropriate locale format
  const value = new Intl.NumberFormat(Localization.currencyLocale, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  }).format(num);

  return value;
};

export const numberWithCommas = (x: number | string) => {
  const y = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return y.replace(/\.00$/, '');
};
export const BUCKET_LEVEL = [
  {
    id: 'B0',
    value: 'B0',
    label: 'B0',
    color: '#39B542',
  },
  {
    id: 'B2',
    value: 'B2',
    label: 'B2',
    color: '#ED9807',
  },
  {
    id: 'B4-B6',
    value: 'B4-B6',
    label: 'B4-B6',
    color: '#E22B16',
  },
];
